<template>
    <div class="page-edit-organisation">

        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="organisation.name" @keyup.enter="submitForm" >
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="inputEmail" type="email" name="email" class="form-control"  v-model="organisation.email" @keyup.enter="submitForm">
                        <label for="inputEmail" class="form-label" >{{ $t("email") }}</label>                
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="inputContactPerson" type="text" name="email" class="form-control"  v-model="organisation.contact_person" @keyup.enter="submitForm">
                        <label for="inputContactPerson" class="form-label" >{{ $t("contact_person") }}</label>                
                    </div>
                </div>
            </div>
            
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="inputAddress1" type="text" name="email" class="form-control"  v-model="organisation.address1" @keyup.enter="submitForm">
                        <label for="inputAddress1" class="form-label" >{{ $t("address1") }}</label>                
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="inputZipcode" type="text" name="zipcode" class="form-control" v-model="organisation.zipcode" @keyup.enter="submitForm">
                        <label for="inputZipcode">{{ $t("zipcode") }}</label>   
                    </div>       
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="inputCity" type="text" name="city" class="form-control" v-model="organisation.city" @keyup.enter="submitForm">
                        <label for="inputCity">{{ $t("city") }}</label>
                    </div>
                </div>
            </div>
            
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-6">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="inputFolderNameImport" type="text" name="email" class="form-control"  v-model="organisation.folder_name_import" @keyup.enter="submitForm">
                        <label for="inputFolderNameImport" class="form-label" >{{ $t("folder_name_import") }}</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="price_per_sms" type="text" name="price_per_sms" class="form-control"  v-model="organisation.price_per_sms" @keyup.enter="submitForm">
                        <label for="price_per_sms" class="form-label" >{{ $t("price_per_sms") }}</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-floating">
                        <input :disabled="!this.canEditOrganisation" id="price_per_email" type="text" name="price_per_email" class="form-control"  v-model="organisation.price_per_email" @keyup.enter="submitForm">
                        <label for="price_per_email" class="form-label" >{{ $t("price_per_email") }}</label>
                    </div>
                </div>
            </div>
            
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
            
            <div class="row my-3">
                <div class="col-6">
                    <div class="row justify-content-center">
                        <div class="col-6 col-md-4">
                            <img :src="get_logo" class="img-fluid" >
                                    
                            <input ref="fileInput" type="file" @input="pickFile" accept="image/png, image/jpeg" hidden>
                        </div>
                        <div class="col-4 col-md-3 align-items-center d-flex">
                            <ul>
                                <li>{{ $t("logo-type") }}</li>
                                <li>{{ $t("logo-size") }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <button class="btn btn-danger float-start mt-3" @click="deleteLogo">{{ $t("Logo löschen") }}</button>
                        </div>
                        <div class="col-6">
                                    
                        </div>
                        <div class="col-3">
                            <button class="btn btn-success float-end mt-3" @click="selectImage">{{ $t("Logo ändern") }}</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditOrgansiation',
    props: {
        organisation: {
            type: Object,
            required: true,
        },
    },
    watch: {
        organisation: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.logoBase64 = this.organisation.get_logo_base64
            }
        }
    },
    data() {
        return {
            previewImage: null,
            logoBase64: null
        }
    },
    mounted(){
        this.logoBase64 = this.organisation.get_logo_base64
    },
    methods: {
        selectImage () {
          this.$refs.fileInput.click()
        },
        pickFile () {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                    this.changeLogo()
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        },
        changeLogo(){
            let input = this.$refs.fileInput
            let file = input.files

            if(file[0].size > 1024 *1024){
                createToast({text: this.$t("Das Logo ist zu groß!")
                              ,title: this.$t("Logo")
                              ,type: 'error'})
                return
            }
            
            const formData = {
                newLogo: this.previewImage
            }

            axios
                .post(`/api/v1/organisation/${this.organisation.id}/update_organisation_logo/`, formData)
                .then(response => {
                    createToast({text: this.$t("Logo wurde geändert")
                              ,title: this.$t("Logo")
                              ,type: 'success'})
                    this.logoBase64 = this.previewImage
                    this.previewImage = null
                })
                .catch(error => {
                    createToast({text: this.$t("Fehler beim Ändern des Logos!")
                              ,title: this.$t("Logo")
                              ,type: 'error'})
                    console.log(JSON.stringify(error))
                })
        },
        deleteLogo(){

            const formData = {
                newLogo: null
            }

            axios
                .post(`/api/v1/organisation/${this.organisation.id}/update_organisation_logo/`, formData)
                .then(response => {
                    createToast({text: this.$t("Logo wurde gelöscht")
                            ,title: this.$t("Logo")
                            ,type: 'success'})
                    this.logoBase64 = null
                    this.previewImage = null
                })
                .catch(error => {
                    createToast({text: this.$t("Fehler beim Löschen des Logos!")
                            ,title: this.$t("Logo")
                            ,type: 'error'})
                    console.log(JSON.stringify(error))
                })
        },
        submitForm() {
            delete this.organisation.logo
            axios
                .patch(`/api/v1/organisations/${this.organisation.id}/`, this.organisation)
                .then(response => {
                    
                    createToast({text: this.$t("organisation altered")
                   ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
        get_logo() {
            console.log('entry get_logo')
            if(this.logoBase64 == null){
                return 
            }
            if(this.logoBase64.startsWith('data:image')){
                return this.logoBase64
            }
            return 'data:image/png;base64,' + this.logoBase64
        },
        canEditOrganisation(){
            return this.$store.getters.userHasPermission('organisation', 'can_write')
        },
    }
}
</script>