<template>
    <div class="row ">
        <div class="col-12 text-center">
            {{ this.getDateFormatted() }}
        </div>
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-between">
                <li class="page-item" >
                    <a class="page-link"  @click="loadPrev()">&laquo;</a>
                </li>
                <li class="page-item" >
                    <a class="page-link" href="#" @click="loadNext()">&raquo;</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="row">
        <div class="col-12 my-2" v-for="(data, orga) in notification_parks" :key="orga">
            <div class="card shadow-sm" >
                <div class="card-header text-center">
                    <p class="h5">{{ orga }}</p>
                    
                </div>
                <div class="card-body ">
                    <table class="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th class="w-50" rowspan="2">{{ $t("park_plant") }}</th>
                                <th class="" rowspan="2">{{ $t("cost") }}</th>
                                <th class="w-25" colspan="2">{{ $t("num_email") }}</th>
                                <th class="w-25" colspan="2">{{ $t("num_sms") }}</th>
                            </tr>
                            <tr>
                                <th>{{ $t("quantity") }}</th>
                                <th>{{ $t("cost") }}</th>
                                <th>{{ $t("quantity") }}</th>
                                <th>{{ $t("cost") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="plant in data.plants"
                                v-bind:key="plant.name">
                                <td> {{ plant.name }}</td>
                                <td> {{ plant.monthly_cost }} €</td>
                                <td> {{ plant.total_email }}</td>
                                <td> {{ plant.price_email }} €</td>
                                <td> {{ plant.total_sms }}</td>
                                <td> {{ plant.price_sms }} €</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Summe</th>
                                <th>{{ data.sum_monthly_cost }} €</th>
                                <th>{{ data.sum_email }}</th>
                                <th>{{ data.sum_email_price }} €</th>
                                <th>{{ data.sum_sms }}</th>
                                <th>{{ data.sum_sms_price }} €</th>
                            </tr>
                        </tfoot>
                    </table>
                
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>
import axios from 'axios'

export default {
    name: 'PlantNotifications',
    components: {
    },
    data() {
        return {
            notification_parks: [],
            currentDate: {}
        }
    },
    mounted(){
        this.getDataForThisMonth()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {

        getDataForThisMonth(){
            console.log('entry getDataForThisMonth')
            if (!this.currentDate || !(this.currentDate instanceof Date)) {
                this.currentDate = new Date();
            }
            var lastDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() +1, 0)
            let startIntervall = {}
            let endIntervall = {}
            startIntervall['day'] = '1'
            startIntervall['month'] = lastDayOfMonth.getMonth()
            startIntervall['year'] = lastDayOfMonth.getFullYear()
            endIntervall['day'] = lastDayOfMonth.getDate()
            endIntervall['month'] = lastDayOfMonth.getMonth()
            endIntervall['year'] = lastDayOfMonth.getFullYear()
            var formData = {}
            formData.startIntervall = startIntervall
            formData.endIntervall = endIntervall
            formData.type_data = 'plain'
            axios
                .post('/api/v1/organisation/get_plant_notification_park/', formData)
                .then(response => {
                    this.notification_parks = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    this.notification_parks = []
                })
            console.log('exit getDataForThisMonth')
        },
        loadNext() {
            this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() +1, 1)
            this.getDataForThisMonth()
        },
        loadPrev() {
            this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() -1, 1)
            this.getDataForThisMonth()
        },

        getDateFormatted(){
            if (!this.currentDate || !(this.currentDate instanceof Date)) {

                return ""
            }
            return this.currentDate.toLocaleDateString('de-DE', {month: '2-digit', year: 'numeric'})
        },
    },
    computed:{
        currentProperties(){
                return {allOrganisations: this.allOrganisations}
            },
        currentNotificationProperties(){
                return {organisation: this.currentOrganisation}
            },
    }
}
</script>
